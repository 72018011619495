import React from "react";
import Contents from "../components/Contents";
import BreadCrumbs from "../components/navigation/BreadCrumbs";
import Sidebar from "../components/navigation/Sidebar";

const ContentPage = ({ imgPath }) => {
  return (
    <div className="page-body pt-lg-5">
      <section>
        <div className="container my-2 text-center">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <img src={imgPath} className="img-fluid " alt="The farm" />
            </div>
          </div>
          <div className="tape-section"></div>
        </div>
      </section>

      <section>
        <div className="container">
          <BreadCrumbs />
        </div>
      </section>

      <section>
        <div className="container my-3">
          <div className="row g-4">
            <div className="col-lg-3">
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <Contents />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContentPage;
