import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./utils/CustomCSS/Main.css";
import Header from "./components/Header";
import SiteRoutes from "./components/navigation/SiteRoutes";
import Footer from "./components/Footer";
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import "./fonts/Heebo-Black.ttf";
import "./fonts/Heebo-Bold.ttf";
import "./fonts/Heebo-ExtraBold.ttf";
import "./fonts/Heebo-Light.ttf";
import "./fonts/Heebo-Medium.ttf";
import "./fonts/Heebo-Regular.ttf";
import "./fonts/Heebo-Thin.ttf";
import { createContext, useEffect, useState } from "react";
import useCurrentTab from "./utils/useCurrentTab";

export const ContentContext = createContext();

export default function App() {
  let [tabsContent, setTabsContent] = useState(null);
  let [modalContent, setModalContent] = useState(null);

  let [tabsContentHeb, setTabsContentHeb] = useState(null);
  let [tabsContentEn, setTabsContentEn] = useState(null);

  let [modalContentHeb, setModalContentHeb] = useState({});
  let [modalContentEn, setModalContentEn] = useState({});

  let [isHebrew, setIsHebrew] = useState(true);

  useEffect(() => {
    getContent();
  }, []);

  useEffect(() => {
    if (isHebrew) {
      setTabsContent(tabsContentHeb);
      setModalContent(modalContentHeb);
    } else {
      setTabsContent(tabsContentEn);
      setModalContent(modalContentEn);
    }
  }, [
    tabsContentHeb,
    tabsContentEn,
    modalContentEn,
    modalContentHeb,
    isHebrew,
  ]);

  const getContent = async () => {
    const tabsContentHeb = await fetch(`/TabsContentHeb.json`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const tabsContentEn = await fetch(`/TabsContentEn.json`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const modalContentHeb = await fetch(`/ModalContentHeb.json`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const modalContentEn = await fetch(`/ModalContentEn.json`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    setTabsContentHeb(await tabsContentHeb.json());
    setTabsContentEn(await tabsContentEn.json());
    setModalContentHeb(await modalContentHeb.json());
    setModalContentEn(await modalContentEn.json());
  };

  return (
    <ContentContext.Provider
      value={{
        tabsContent,
        tabsContentHeb,
        tabsContentEn,
        modalContent,
        isHebrew,
        setIsHebrew,
      }}
    >
      <Router>
        <div className={`App ${isHebrew ? "rtl" : "ltr"}`}>
          <Header />
          <SiteRoutes />
          <Footer />
        </div>
      </Router>
    </ContentContext.Provider>
  );
}
