import React, { useContext } from "react";
import { Link } from "react-router-dom";
import useCurrentTab from "../../utils/useCurrentTab";
import { ContentContext } from "../../App";

const BreadCrumbs = () => {
  const { currentLang, currentTab, currentSideBarTab } = useCurrentTab();
  const { isHebrew } = useContext(ContentContext);

  return (
    <div className={`${isHebrew ? "text-end" : "text-start"} p-2 mt-3`}>
      <span>
        <Link to={isHebrew ? "/heb" : "/en"} className="text-warning">
          {isHebrew ? "בית" : "Home"}
        </Link>
        <span> &gt; </span>
      </span>
      <span>
        <span> {currentTab}</span>
        <span> &gt; </span>
      </span>
      <span>
        <span>{currentSideBarTab}</span>
      </span>
    </div>
  );
};

export default BreadCrumbs;
