import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate, Switch } from "react-router-dom";
import HomeHeb from "../../pages/HomeHeb";
import HomeEn from "../../pages/HomeEn";
import ContentPage from "../../pages/ContentPage";
import ContentPageTest from "../../pages/ContentPageTest";
import { ContentContext } from "../../App.js";
import useCurrentTab from "../../utils/useCurrentTab";

const SiteRoutes = () => {
  const { tabsContentHeb, tabsContentEn, isHebrew, setIsHebrew } =
    useContext(ContentContext);
  const { currentLang } = useCurrentTab();

  return (
    <Routes>
      <Route exact path="/heb" element={<HomeHeb />} />
      <Route exact path="/en" element={<HomeEn />} />

      {tabsContentHeb?.map((tab) => {
        return (
          <Route
            path={`/heb/${encodeURIComponent(tab.tabName)}/:sideTab`}
            name={tab.tabName}
            element={<ContentPage imgPath={tab.imgPath} />}
            key={tab.tabName}
          />
        );
      })}

      {tabsContentEn?.map((tab) => {
        return (
          <Route
            path={`/en/${encodeURIComponent(tab.tabName)}/:sideTab`}
            name={tab.tabName}
            element={<ContentPage imgPath={tab.imgPath} />}
            key={tab.tabName}
          />
        );
      })}

      <Route exact path="" element={<Navigate to="/heb" />} />
      <Route exact path="/" element={<Navigate to="/heb" />} />
    </Routes>
  );
};

export default SiteRoutes;
