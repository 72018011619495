import React, { useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import useCurrentTab from "../../utils/useCurrentTab";
import { ContentContext } from "../../App.js";

const Navbar = () => {
  let [collapseOpen, setCollpaseOpen] = useState(false);
  const { tab: currentTab } = useCurrentTab();
  const toggleButton = useRef();
  const { tabsContent, isHebrew, setIsHebrew } = useContext(ContentContext);

  const doToggleButton = () => {
    if (collapseOpen) {
      toggleButton.current.click();
    }
  };

  return (
    <nav className="navbar navbar-expand-md navbar-light ltr mt-4">
      <div className="container justify-content-center">
        {/* logo and donation buutons */}
        <div
          className="text-center mx-5 align-items-md-center flex-md-row flex-column"
          style={{ display: "flex" }}
        >
          <div
            className="text-center mx-5 align-items-center flex-row"
            style={{ display: "flex" }}
          >
            <div>
              <Link
                to={isHebrew ? "/en" : "/heb"}
                className="navbar-brand navbar-brand-coustum"
              >
                <img
                  src="/images/logo.png"
                  className=""
                  alt="otspace21+ logo"
                />
              </Link>
            </div>
            {/* change language button, linking to home because cant change url's language */}
            <div>
              <Link to={isHebrew ? "/en" : "/heb"}>
                <button
                  className="btn btn-primary text-white btn-sm"
                  onClick={() => {
                    setIsHebrew(!isHebrew);
                  }}
                >
                  {isHebrew ? "English" : "עברית"}
                </button>
              </Link>
            </div>
          </div>
          {/* collapse menu button */}
          <button
            className="navbar-toggler mx-auto btn my-2"
            data-bs-toggle="collapse"
            data-bs-target="#navmenu"
            ref={toggleButton}
            onClick={() => setCollpaseOpen((prev) => !prev)}
          >
            <img
              src={collapseOpen ? `/images/x-lg.svg` : `/images/list.svg`}
              className="p-1"
              alt="open or close collapse menu"
            ></img>
          </button>
        </div>

        {/* collapse nav bar */}
        <div
          className="collapse navbar-collapse"
          id="navmenu"
          style={{ flexGrow: "0" }}
        >
          <ul className="navbar-nav  mx-auto ">
            {tabsContent?.map((tab, index) => {
              return (
                // nav-bar tag names
                <li className="nav-item ms-auto dropdown " key={index}>
                  <Link
                    to={`/${isHebrew ? "heb" : "en"}/${tab.tabName}/${
                      tab.defaultSideBarTab
                    }`}
                    className={`nav-link ${
                      currentTab == tab.tabName ? "active" : ""
                    } `}
                    onClick={() => {
                      doToggleButton();
                    }}
                  >
                    <span>{tab.tabName}</span>
                  </Link>
                  {/* sub menu */}
                  <ul
                    className="dropdown-menu"
                    style={{ right: "0px" }}
                    id={`#${tab}`}
                  >
                    {tab.sideTabs?.map((sideTab, index) => {
                      return (
                        <li key={index} className="dropdown-item text-end ">
                          <Link
                            to={`/${tab.tabName}/${sideTab.sideBarName}`}
                            style={{
                              textDecoration: "none",
                              color: "rgba(0, 0, 0, 0.7)",
                            }}
                          >
                            {sideTab.sideBarName}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
            {/* adding the home nav element */}
            <li
              className="nav-item ms-auto dropdown "
              key={tabsContent?.length + 1}
            >
              <Link
                to={isHebrew ? "/heb" : "/en"}
                className={`nav-link ${currentTab == null ? "active" : ""}`}
                onClick={() => {
                  doToggleButton();
                }}
              >
                <span>{isHebrew ? "אודות" : "About"}</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
