import React, { useContext, useEffect, useState } from "react";
import CreateModal from "./CreateModal";
import useCurrentTab from "../utils/useCurrentTab";
import { ContentContext } from "../App";

const Contents = () => {
  const [currentContentItem, setCurrentContentItem] = useState(null);
  const { currentTab, currentSideBarTab } = useCurrentTab();
  const { tabsContent, modalContent } = useContext(ContentContext);

  useEffect(() => {
    const currentTabObj = tabsContent?.find(
      (tabObj) => tabObj.tabName === currentTab
    );
    setCurrentContentItem(
      currentTabObj?.sideTabs?.find((sideBarObj) => {
        return sideBarObj.sideBarName === currentSideBarTab;
      })
    );
  }, [currentSideBarTab, tabsContent, modalContent, currentTab]);

  return (
    <>
      {currentContentItem ? (
        <div className=" border rounded p-3">
          <div
            style={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: currentContentItem.text }}
          ></div>
          {currentContentItem.modal && (
            <CreateModal
              modalTitle={modalContent.hebTitle}
              modalBody={modalContent.hebBody}
              modalLegend={modalContent.hebLegend}
              modalMessageTitle={modalContent.hebMessageTitle}
              modalMessagePlaceholder={modalContent.hebMessagePlaceholder}
              modalAblitiesOptions={modalContent.modalAblitiesOptions}
              modalReasonToJoin={modalContent.modalReasonToJoin}
            />
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Contents;
