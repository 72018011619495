import React, { useContext } from "react";
import CarouselItem from "../components/CarouselItem";
import CreateModal from "../components/CreateModal";
import ReactPlayer from "react-player";
import StaffCard from "../components/StaffCard";
import { ContentContext } from "../App";

const HomeHeb = () => {
  const { modalContent } = useContext(ContentContext);

  return (
    <div className="container">
      <div className="page-body pt-lg-5 pt-5">
        {/* <CarouselItem /> */}
        <div className="row justify-content-center">
          <div className="col-lg-8 col-12">
            <img
              src="/images/from_farm/AboutPic.jpg"
              className="img-fluid"
              alt="The farm"
            />
          </div>
        </div>

        {/* About Us */}
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col border-top border-warning pt-4">
                <h4 className="text-center">
                  <span className="text-warning">+OTspace21</span> אוטיזם אחרי
                  21
                </h4>
                <p style={{ textAlign: "justify" }}>
                  מיזם <span className="fw-bold">+OTspace21</span> הוקם עבור
                  בוגרים על הספקטרום האוטיסטי שהגיעו לגיל 21. אנו דוגלים בתפיסת
                  האוטיסט כשלם בעל יכולות, אשר יכול להמשיך לחיות, לעבוד, ללמוד
                  ולשחק כאשר הוא מקבל את התמיכה המתאימה.
                  <span className="fw-bold"> +OTspace21 </span>חלוצה בהעלאת
                  המודעות לגבי השירותים הדרושים עבור הקהילה האוטיסטית הבוגרת
                  ותומכת בשינויי חקיקה חברתית ברמה המקומית והארצית.
                </p>
                <p>
                  <a
                    href="https://hackautism.virtualmagix.com/Exhibitors/Detail?id=c4714d1b-0b17-4f7a-aef2-fbb8a1db0db5"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    כניסה לתשתית הוירטואלית של המיזם.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Petition */}
        {modalContent && (
          <section>
            <div className="row justify-content-center ">
              <div className="col-lg-7 col-12 ">
                <div className="row my-3 px-2 py-2 bg-primary bg-opacity-50 rounded align-items-center ">
                  <div className="col-sm-7 col-12 ">
                    <div className=" m-3">
                      <h3 className="text-white text-center">
                        #אוטיזם אחרי 21
                      </h3>
                      <p
                        className="text-white "
                        style={{ textAlign: "justify" }}
                      >
                        קמפיין
                        <span className="text-warning">#אוטיזם_אחרי_21</span>
                        צמח מתוך מיזם +OTspace21 שדוגל בהרחבת המודעות, הטיפול
                        והתמיכה עבור רווחתם המרבית של הבוגרים לאורך כל חייהם.
                      </p>

                      <CreateModal
                        modalTitle={modalContent.hebTitle}
                        modalBody={modalContent.hebBody}
                        modalLegend={modalContent.hebLegend}
                        modalMessageTitle={modalContent.hebMessageTitle}
                        modalMessagePlaceholder={
                          modalContent.hebMessagePlaceholder
                        }
                        modalAblitiesOptions={modalContent.modalAblitiesOptions}
                        modalReasonToJoin={modalContent.modalReasonToJoin}
                      />
                    </div>
                  </div>
                  <div className="col-sm-5 col-12 px-sm-0 px-3 ">
                    <img
                      className="img-fluid "
                      src="images/from_farm/DSC_0345.jpg"
                      alt="The farm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {/* Showcase */}
        <div className="container">
          <div className="row text-center align-items-center justify-content-center my-3">
            <div className="col-lg-8 border-top border-bottom border-warning text-warning">
              <h3>החזון שלנו</h3>
            </div>
          </div>
          {/* First statement */}
          <div className="row justify-content-center align-items-center g-4 mt-3 ">
            <div className="col-8 col-lg-4">
              <h5 className="text-center text-primary">OPEN SPACE</h5>
              <p style={{ textAlign: "justify" }}>
                פיתחנו תוכנית שרצה במשך יותר מארבע שנים, הסתמכנו על מחקרים שהראו
                דרכים רבות ושונות כיצד שטחים ירוקים במרחב פתוח יכולים לתרום
                לרווחתם של אנשים עם אוטיזם, רובם שגשגו בפעילויות חוץ, גינון,
                טיולים, רכיבה טיפול ועבודה עם בעלי חיים. +OTspace21 תספק המשך
                חינוך ותוכניות יום הכוללות פיתוח יכולות מקצועיות וכישורי חיים,
                במרחב פתוח משמעותי.
              </p>
            </div>
            <div className="col-4 col-lg-2">
              <img
                src="/images/from_farm/DSC_0340_for_rounded.jpg"
                className="img-fluid rounded-circle"
                alt="The farm"
              />
            </div>
          </div>
          {/* Second statement */}
          <div className="row justify-content-center align-items-center g-4 mt-3 ">
            <div className="col-4 col-lg-2">
              <img
                src="/images/from_farm/DSC_0027_for_rounded.jpg"
                className="img-fluid rounded-circle"
                alt="The "
              />
            </div>
            <div className="col-8 col-lg-4 ">
              <h5 className="text-primary text-center">
                מקום אידאלי לבוגרים עם אוטיזם
              </h5>
              <p style={{ textAlign: "justify" }}>
                בחווה יהיו מתחמי רכיבה ואורוות סוסים בהם יעברו הבוגרים רכיבה
                טיפולית. במתחם החקלאי, בחממה האקולוגית ובגן החושים ילמדו על
                הצמחים והפרחים, תבלינים, ירקות, צמחים מהגינה ועד הצלחת. מודלים
                סביבתיים לגירוי והתפתחות חושית. סדנאות ועבודות יצירה מוסיקה ציור
                ופיסול. סוסים ובעלי חיים נוספים שנמצאים במקום מאפשרים לדיירים
                לטפח ולהתחבר אליהם.
              </p>
            </div>
          </div>

          {/* video */}
          <section>
            <div className="container my-5">
              <div
                className=" row align-items-center justify-content-center p-5"
                style={{
                  background: `url("/images/video_bg.JPG") no-repeat center `,
                  backgroundSize: "cover",
                }}
              >
                <div className="col-lg-5 col-12 text-white ">
                  <blockquote className="blockquote">
                    <p c style={{ textAlign: "justify" }}>
                      "בגיל 21 בוגר על הספקטרום לא מתגבר על האוטיזם, להפך, הקושי
                      רק גדל. כמות המיזמים הסיוע והעזרה פחותים דרמטית מהמעטפת
                      התומכת אליה הורגל. ל-100 אלף אזרחי המדינה אין מענה הוליסטי
                      לצרכיהם הרגשיים והחברתיים מיזם OTdpace21+ עושה בדיוק את
                      זה."
                    </p>
                    <footer className="blockquote-footer">
                      חדוה, מובילה את החווה הטיפולית כבר 22 שנה
                    </footer>
                  </blockquote>
                </div>
                <div className="col-lg-4 col-12 mt-5 my-lg-1 me-5">
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=lvAJwCBSz7U"
                    className=""
                    width="80%"
                    height="180px"
                  />
                </div>
              </div>
            </div>
          </section>

          {/* Staff */}
          <section>
            <div className="container pt-2">
              <div className="row text-center border-top border-bottom border-warning text-warning my-3">
                <h3>הצוות שלנו</h3>
              </div>
              <div className="container-fluid content-row">
                <div className="row g-4 mt-2 pb-3 bg-primary bg-opacity-50 justify-content-center">
                  {/* <div className="card-group"> */}
                  {/* <div className="row mt-2 pb-3 bg-primary bg-opacity-50 d-flex align-items-stretch"> */}
                  <StaffCard
                    imgName="chedva"
                    staffName="חדוה רומנו"
                    staffDuty={`מייסדת`}
                    staffElaborate={`יזמית חברתית בתחום מימוש עתיד משמעותי עבור מבוגרים על הספקטרום`}
                  />
                  <StaffCard
                    imgName="michal"
                    staffName="מיכל צרפתי"
                    staffDuty={`עו"ד`}
                    staffElaborate={
                      "ליווי וייעוץ משפטי. חברה מורשה בלשכת עורכי הדין בישראל משנת 2005"
                    }
                  />
                  <StaffCard
                    imgName="yael1"
                    staffName="יעל יונה"
                    staffDuty="מנהלת מטפלים"
                    staffElaborate="יועצת חינוכית במסלול חינוך מיוחד M.A"
                  />
                  <StaffCard
                    imgName="itai"
                    staffName="איתי אופיר"
                    staffDuty="מנהל החווה"
                    staffElaborate={
                      "אחראי על עבודות המשק, דאגה לחיות, למכונות, ולשביעות רצון העובדים"
                    }
                  />
                </div>
              </div>
              {/* </div> */}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HomeHeb;
