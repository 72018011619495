import React, { useRef, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ContentContext } from "../../App";
import useCurrentTab from "../../utils/useCurrentTab";

const Sidebar = () => {
  const { tabsContent } = useContext(ContentContext);
  const { currentTab, currentSideBarTab } = useCurrentTab();
  const [currentTabObj, setCurrentTabObj] = useState(null);
  let [collapseOpen, setCollpaseOpen] = useState(false);

  const toggleButton = useRef();

  const doToggleButton = () => {
    if (collapseOpen) {
      toggleButton.current.click();
    }
  };

  useEffect(() => {
    setCurrentTabObj(() => {
      if (currentTab !== null && tabsContent != null) {
        return tabsContent.find((tabObj) => tabObj.tabName === currentTab);
      } else {
        return null;
      }
    });
  }, [currentTab, tabsContent]);

  return (
    <nav className="navbar-expand-lg">
      <button
        className="navbar-toggler mx-auto"
        data-bs-toggle="collapse"
        data-bs-target="#sidebar"
        onClick={() => setCollpaseOpen((prev) => !prev)}
        ref={toggleButton}
      >
        <img
          src={collapseOpen ? `/images/x-lg.svg` : `/images/list.svg`}
          className="p-1"
          alt="open or close side bar menu"
        ></img>
      </button>

      <div className="collapse navbar-collapse mt-2" id="sidebar">
        <ul className="list-group list-group-flush p-0" id="side-bar">
          {currentTabObj &&
            currentTabObj.sideTabs.map((sideBarItem, index) => {
              return (
                <li
                  className={`list-group-item side-bar ${
                    currentSideBarTab == sideBarItem.sideBarName ? "active" : ""
                  }`}
                  key={index}
                >
                  <Link
                    to={`/${currentTab}/${sideBarItem.sideBarName}`}
                    onClick={() => {
                      doToggleButton();
                    }}
                    className={`nav-link ${
                      currentSideBarTab == sideBarItem.sideBarName
                        ? "active"
                        : ""
                    }`}
                    style={{ textDecoration: "none", color: "gray" }}
                  >
                    <span>{sideBarItem.sideBarName}</span>
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
