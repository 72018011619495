import React from "react";
import Navbar from "./navigation/Navbar";

const Header = () => {
  return (
    <div className=" fixed-top bg-white">
      <img
        src="/images/headerWave4.svg"
        style={{ position: "absolute" }}
        alt="header wave drawing"
      />
      <Navbar />
    </div>
  );
};

export default Header;
