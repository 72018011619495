import { useLocation } from "react-router-dom";
import { ContentContext } from "../App.js";
import { useContext } from "react";

const useCurrentTab = () => {
  const { setIsHebrew, isHebrew } = useContext(ContentContext);

  const location = useLocation();
  const url = decodeURIComponent(location.pathname);
  const langElement = url.match(/\/(heb|en)/) || null;
  const currentLang = langElement != null ? langElement[1] : null;
  const tabElement = url.match(/\/(?:heb|en)\/(\W+|\w+)\/(.*)/) || null;
  const [currentTab, currentSideBarTab] =
    tabElement !== null ? [tabElement[1], tabElement[2]] : [null, null];

  if (currentLang != null) {
    setIsHebrew(currentLang == "heb");
  }
  return { currentLang, currentTab, currentSideBarTab };
};

export default useCurrentTab;
