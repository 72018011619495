import Spinner from "react-bootstrap/Spinner";
import React, { useState, useContext } from "react";

import { ContentContext } from "../App";

const FULLNAME_INDEX = 0;
const TELEPHONE_INDEX = 2;
const EMAIL_INDEX = 1;
const MESSAGE_INDEX = 3;
const ABILITIES_INDEX = 4;
const REASON_INDEX = 5;

const CreateModal = ({
  modalTitle,
  modalBody,
  modalLegend,
  modalMessageTitle,
  modalMessagePlaceholder,
  modalAblitiesOptions,
  modalReasonToJoin,
}) => {
  let [isFormSubmited, setIsFormSubmited] = useState(false);
  let [hideLoading, setHideLoading] = useState(true);
  let [formError, setFormError] = useState("");

  const { isHebrew } = useContext(ContentContext);

  const exitModal = () => {
    setIsFormSubmited(false);
    setHideLoading(true);
  };

  const handleSubmit = async (e) => {
    setHideLoading(false);
    e.preventDefault();
    const fullName = e.target[FULLNAME_INDEX].value;
    const telephone = e.target[TELEPHONE_INDEX].value;
    const email = e.target[EMAIL_INDEX].value;
    const message = e.target[MESSAGE_INDEX].value;
    const abilities = e.target[ABILITIES_INDEX].value;
    const reason = e.target[REASON_INDEX].value;

    const response = await fetch(`/api/otafter21`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        full_name: fullName,
        telephone: telephone,
        email_address: email,
        message: message,
        abilities: abilities,
        reason: reason,
      }),
    });
    if (response.status !== 201) {
      const responseError = await response.text();
      setFormError(responseError);
    }
    setHideLoading(true);
    setIsFormSubmited(true);
  };

  return (
    <div>
      <button
        data-bs-toggle="modal"
        data-bs-target="#signUpModal"
        className="btn btn-primary text-white "
      >
        {isHebrew ? "לחצו כאן" : "Click Here"}
      </button>

      <div className="modal" id="signUpModal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content rtl">
            {isFormSubmited === false ? (
              <>
                <div className="modal-header">
                  <h5 className="modal-title text-primary">{modalTitle} </h5>
                  <button
                    className="btn btn-border"
                    data-bs-dismiss="modal"
                    onClick={exitModal}
                  >
                    <img src="/images/x-lg.svg" alt="close modal button" />
                  </button>
                </div>
                <div className={`modal-body ${isHebrew ? "rtl" : "ltr"}`}>
                  {hideLoading ? (
                    <>
                      <div
                        style={{ textAlign: "justify" }}
                        dangerouslySetInnerHTML={{
                          __html: modalBody,
                        }}
                      ></div>

                      <form className="mt-5 " onSubmit={handleSubmit}>
                        <legend>{modalLegend}</legend>
                        <h5>
                          {isHebrew
                            ? "ספר.י לנו קצת יותר על עצמך"
                            : "Tell us about yourself"}
                        </h5>
                        <br></br>
                        <div className="mb-3">
                          <label for="name" className="form-label">
                            {isHebrew ? "שם מלא" : "Full Name"}
                          </label>
                          <input
                            type="text"
                            className="form-control input-lg"
                            id="name"
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <label for="email" className="form-label">
                            {isHebrew ? "כתובת מייל" : "Email Address"}
                          </label>
                          <input
                            type="email"
                            placeholder="name@example.com"
                            className="form-control"
                            id="email"
                            aria-describedby="emailHelp"
                            required
                          />
                          <div id="emailHelp" className="form-text">
                            {isHebrew
                              ? "אנחנו לא נשתף את המייל שלכם עם אף אחד"
                              : "We will not share your email with anyone"}
                          </div>
                        </div>

                        <div className="mb-3">
                          <label for="telephone" className="form-label">
                            {isHebrew ? "מספר טלפון" : "Phone Number"}
                          </label>
                          <input
                            type="tel"
                            pattern="[0-9]{2,3}-?[0-9]{7}"
                            className="form-control"
                            id="telephone"
                          />
                        </div>

                        <div className="mb-3">
                          <label for="abilities" className="form-label">
                            {isHebrew ? "תחומי התמחות" : "Expertise"}
                          </label>
                          <select
                            name="abilities"
                            className="form-select"
                            id="abilities"
                          >
                            <option disabled selected value>
                              {isHebrew
                                ? "בחרו באפשרות המתאימה..."
                                : "Please choose..."}
                            </option>
                            {modalAblitiesOptions?.map((ability) => {
                              return <option value={ability}>{ability}</option>;
                            })}
                          </select>
                        </div>

                        <div className="mb-3">
                          <label for="reason" className="form-label">
                            {isHebrew
                              ? "הסיבה העיקרית להצטרפות אלינו"
                              : "Why have you joined us"}
                          </label>
                          <select
                            name="reason"
                            className="form-select"
                            id="reason"
                          >
                            <option disabled selected value>
                              {isHebrew
                                ? "בחרו באפשרות המתאימה..."
                                : "Please choose..."}
                            </option>
                            {modalReasonToJoin?.map((ability) => {
                              return <option value={ability}>{ability}</option>;
                            })}
                          </select>
                        </div>

                        <div className="mb-3">
                          <label for="message" className="form-label">
                            {modalMessageTitle}
                          </label>
                          <textarea
                            className="form-control"
                            id="message"
                            placeholder={modalMessagePlaceholder}
                          />
                        </div>

                        <button
                          className="btn btn-primary text-white"
                          type="submit"
                        >
                          {isHebrew ? "שליחה" : "Send"}
                        </button>
                      </form>
                    </>
                  ) : (
                    <Spinner animation="border" hidden={hideLoading} />
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="modal-header">
                  {/* <h5 className="modal-title">תודה על ההרשמה</h5> */}
                  <button
                    className="btn btn-border"
                    data-bs-dismiss="modal"
                    onClick={exitModal}
                  >
                    <img src="/images/x-lg.svg" alt="close modal button" />
                  </button>
                </div>
                <div className="modal-body text-end">
                  {formError ? (
                    <div>
                      <h5>נראה שהייתה שגיאה, נסו שוב:</h5>
                      <p className="ltr">{formError}</p>
                    </div>
                  ) : (
                    <h3>הרשמתך נקלטה, נחזור אליך בהקדם</h3>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateModal;
