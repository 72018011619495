import React from "react";

const Footer = () => {
  return (
    <footer className="text-center  bg-light text-muted mt-4 ">
      <img src="/images/footerWave.svg" alt="wave drawing for footer" />
      <div
        className="text-white pt-3"
        style={{ backgroundColor: "#D4A65B", minHeight: "50px" }}
      >
        <div className="d-sm-flex justify-content-center">
          <span>
            <img
              src="/images/phone1.svg"
              className="ms-2"
              style={{ width: "1.25em" }}
              alt="phone cartoon"
            />
            054-7770915
          </span>
          <span className="mx-3">|</span>
          <span>
            <img
              src="/images/address1.svg"
              className="ms-2"
              style={{ width: "1.25em" }}
              alt="address cartoon"
            />
            הרב-קוק 9, ראשון לציון
          </span>
          <span className="mx-3">|</span>
          <span>
            <img
              src="/images/mail1.svg"
              className="ms-2"
              style={{ width: "1.25em" }}
              alt="email cartoon"
            />
            chedvaromano@gmail.com
          </span>
        </div>
        <div className="mt-5">
          <span className="text-white ">&copy; Tamar Tavor 2022 inc</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
