import React, { useContext } from "react";
import CarouselItem from "../components/CarouselItem";
import CreateModal from "../components/CreateModal";
import ReactPlayer from "react-player";
import StaffCard from "../components/StaffCard";
import { ContentContext } from "../App";

const HomeEn = () => {
  const { modalContent } = useContext(ContentContext);

  return (
    <div className="container">
      <div className="page-body pt-lg-5 pt-5">
        {/* <CarouselItem /> */}
        <div className="row justify-content-center">
          <div className="col-lg-8 col-12">
            <img
              src="/images/from_farm/AboutPic.jpg"
              className="img-fluid"
              alt="The farm"
            />
          </div>
        </div>

        {/* About Us */}
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col border-top border-warning pt-4">
                <h4 className="text-center">
                  <span className="text-warning">OTspace21+ </span>Autism After
                  21
                </h4>
                <p style={{ textAlign: "justify" }}>
                  The <span className="fw-bold">OTspace21+ </span>project was
                  established for young adults on the Autistic spectrum who have
                  reached the age of 21. We advocate that the perception of the
                  Autistic person be one of abilities, who can continue to live,
                  work, learn and play when they receive the appropriate
                  support.<span className="fw-bold"> OTspace21+ </span>is a
                  pioneer in raising awareness about the services needed for the
                  adult autistic community and supports changes in social
                  legislation at the local and national level.
                </p>
                <p>
                  <a
                    href="https://hackautism.virtualmagix.com/Exhibitors/Detail?id=c4714d1b-0b17-4f7a-aef2-fbb8a1db0db5"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    Access to the virtual infrastructure of the project.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Petition */}
        {modalContent && (
          <section>
            <div className="row justify-content-center ">
              <div className="col-lg-7 col-12 ">
                <div className="row my-3 px-2 py-2 bg-primary bg-opacity-50 rounded align-items-center ">
                  <div className="col-sm-7 col-12 ">
                    <div className=" m-3">
                      <h3 className="text-white text-center">
                        #Autism after 21
                      </h3>
                      <p
                        className="text-white "
                        style={{ textAlign: "justify" }}
                      >
                        The campaign
                        <span className="text-warning"> #Autism after 21 </span>
                        grew out of the OTspace21+ project that advocates
                        expanding awareness, care and support for the maximum
                        well-being of adults throughout their lives.
                      </p>

                      <CreateModal
                        modalTitle={modalContent.hebTitle}
                        modalBody={modalContent.hebBody}
                        modalLegend={modalContent.hebLegend}
                        modalMessageTitle={modalContent.hebMessageTitle}
                        modalMessagePlaceholder={
                          modalContent.hebMessagePlaceholder
                        }
                        modalAblitiesOptions={modalContent.modalAblitiesOptions}
                        modalReasonToJoin={modalContent.modalReasonToJoin}
                      />
                    </div>
                  </div>
                  <div className="col-sm-5 col-12 px-sm-0 px-3 ">
                    <img
                      className="img-fluid "
                      src="images/from_farm/DSC_0345.jpg"
                      alt="The farm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {/* Showcase */}
        <div className="container">
          <div className="row text-center align-items-center justify-content-center my-3">
            <div className="col-lg-8 border-top border-bottom border-warning text-warning">
              <h3>Our Vision</h3>
            </div>
          </div>
          {/* First statement */}
          <div className="row justify-content-center align-items-center g-4 mt-3 ">
            <div className="col-8 col-lg-4">
              <h5 className="text-center text-primary">OPEN SPACE</h5>
              <p style={{ textAlign: "justify" }}>
                We developed a program that ran for more than four years,
                drawing on research that showed the many ways green open spaces
                contributes to the well-being of people with Autism, most of
                whom thrive in outdoor activities, gardening, hiking, riding
                therapy and working with animals. OTspace21+ will provide
                continuing education and day programs that include the
                development of professional abilities and life skills, in a
                significant open space.
              </p>
            </div>
            <div className="col-4 col-lg-2">
              <img
                src="/images/from_farm/DSC_0340_for_rounded.jpg"
                className="img-fluid rounded-circle"
                alt="The farm"
              />
            </div>
          </div>
          {/* Second statement */}
          <div className="row justify-content-center align-items-center g-4 mt-3 ">
            <div className="col-4 col-lg-2">
              <img
                src="/images/from_farm/DSC_0027_for_rounded.jpg"
                className="img-fluid rounded-circle"
                alt="The "
              />
            </div>
            <div className="col-8 col-lg-4 ">
              <h5 className="text-primary text-center">
                An ideal place for adults with Autism
              </h5>
              <p style={{ textAlign: "justify" }}>
                The farm will have riding facilities and horse stables where the
                participants will undergo therapeutic riding. In the
                agricultural complex, in the ecological greenhouse and in the
                garden of the senses, they will learn about plants and flowers,
                spices, vegetables, plants from the garden to the plate. These
                environmental models are for stimulation and sensory
                development. Workshops and creative works, music, painting and
                sculpture are also part of the program. Horses and other animals
                on the premises allow residents to nurture and connect with
                them.
              </p>
            </div>
          </div>

          {/* video */}
          <section>
            <div className="container my-5">
              <div
                className=" row align-items-center justify-content-center p-5"
                style={{
                  background: `url("/images/video_bg.JPG") no-repeat center `,
                  backgroundSize: "cover",
                }}
              >
                <div className="col-lg-5 col-12 text-white ">
                  <blockquote className="blockquote">
                    <p c style={{ textAlign: "justify" }}>
                      "At the age of 21, an adult on the spectrum does not
                      overcome Autism, on the contrary, the difficulty only
                      increases. The number of aid and assistance projects is
                      dramatically less than the support services to which they
                      were accustomed. Over 100,000 citizens of the country do
                      not have a holistic answer to their emotional and social
                      needs. The OTdpace21+ project does exactly that."
                    </p>
                    <footer className="blockquote-footer">
                      Hedva, has been leading the therapeutic farm for 22 years
                    </footer>
                  </blockquote>
                </div>
                <div className="col-lg-4 col-12 mt-5 my-lg-1 me-5">
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=lvAJwCBSz7U"
                    className=""
                    width="80%"
                    height="180px"
                  />
                </div>
              </div>
            </div>
          </section>

          {/* Staff */}
          <section>
            <div className="container pt-2">
              <div className="row text-center border-top border-bottom border-warning text-warning my-3">
                <h3>Our Team</h3>
              </div>
              <div className="container-fluid content-row">
                <div className="row g-4 mt-2 pb-3 bg-primary bg-opacity-50 justify-content-center">
                  {/* <div className="card-group"> */}
                  {/* <div className="row mt-2 pb-3 bg-primary bg-opacity-50 d-flex align-items-stretch"> */}
                  <StaffCard
                    imgName="chedva"
                    staffName="Hedva Romano"
                    staffDuty={`Founder`}
                    staffElaborate={`Social entrepreneur in the field of realizing a meaningful future for adults on the spectrum`}
                  />
                  <StaffCard
                    imgName="michal"
                    staffName="Michal Tzarfti"
                    staffDuty={`Lawyer`}
                    staffElaborate={
                      "Legal support and advice. Licensed member of the Israel Bar Association since 2005"
                    }
                  />
                  <StaffCard
                    imgName="yael1"
                    staffName="Yael Yona"
                    staffDuty=" Supervisor of therapists"
                    staffElaborate="Educational consultant in the special education track M.A"
                  />
                  <StaffCard
                    imgName="itai"
                    staffName="Etai Ofer"
                    staffDuty="Farm Manager"
                    staffElaborate={
                      "Responsible for farm work, care for animals, machinery, and employee satisfaction"
                    }
                  />
                </div>
              </div>
              {/* </div> */}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HomeEn;
