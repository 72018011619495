import React from "react";

const StaffCard = ({ imgName, staffName, staffDuty, staffElaborate }) => {
  return (
    <div className="col-md-3 col-sm-6 col-8">
      <div
        className="card h-100"
        // style={{ minHeight: "350px" }}
      >
        <div className="card-body text-center">
          <img
            src={`/images/farm_staff/${imgName}.jpg`}
            className="rounded img-fluid"
            style={{ height: "200px", width: "auto" }}
            alt="staff"
          />
          <h5 className="card-title mt-2">{staffName}</h5>
          <h6 cl>{staffDuty}</h6>
          <p className="card-text">{staffElaborate}</p>
        </div>
      </div>
    </div>
  );
};

export default StaffCard;
